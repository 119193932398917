import React, { useCallback } from 'react';
import { Link, LinkWithIcon } from 'components';
import styled from 'styled-components';
import { media } from 'theme';
import useSearch from 'hooks/useSearch';
import { useGlobalContext } from 'constants/context';

const LogoText = styled.span`
  font-size:24px;
  font-weight:bold;
  white-space:nowrap
`;
const Container = styled.footer`
    padding: 20px 16px 20px;
  ${media.greaterThan('md')`      
  padding: 90px 80px 100px;    
  `}
  ${'' /* ${media.greaterThan('1624px')`
  padding: 90px 0px 100px;
  `} */}
  min-height: 232px;
  display: flex;  
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  width:100%;
  flex-direction:column;
`;
const Row = styled.div`
  display: flex;
`;
const Line = styled.div`
  font-size: 9px;
  color: #272727;
  line-height: 16px
`;
const Footer = () => {
  const { setContextState } = useGlobalContext();
  const { setSearch, ifNotHomeGoToHome } = useSearch();
  const onClickCatalog = useCallback(() => {
    setSearch('');
    ifNotHomeGoToHome();
    setContextState((state) => ({ ...state, filters: [] }));
    window.scrollTo(0, 0);
  }, [ifNotHomeGoToHome, setContextState, setSearch]);
  return (
    <Container className="hide-on-mobile">
      <Column>
        <Row className="catalog-website-line">
          <Link className="logo-link" onClick={onClickCatalog} to="/">
            <LogoText className="logo-text">Artphilein Library Catalog</LogoText>
          </Link>
          <LinkWithIcon iconAtStart iconAtEnd marginTopIcon={-2} className="logo-link" to="/" local={false}>
            <span>Artphilein Website</span>
          </LinkWithIcon>
        </Row>
        <Column className="about-site">
          <Line>
            Artphilein Library | 1° floor | Via Ferruccio Pelli 13 | 6900 Lugano (Switzerland) | Tel: <a href="tel:+410919220038">+41 (0)91 922 00 38</a> | E-mail: <a href="mailto:library@artphilein.org">library@artphilein.org</a>
          </Line>
          <Line>
            © Copyright 2019 <Link to="/" local={false} className="underline">Artphilein Foundation</Link>
          </Line>
        </Column>
      </Column>
    </Container>
  );
};

export default Footer;
